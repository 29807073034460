<template>
  <!-- CONTENT -->
  <div
    v-shortkey="shortkeySlideImage"
    @shortkey="handleSlideImageShortkey"
    id="specimen-results-wrapper"
    class="container"
  >
    <g-code-popup />
    <SlideImagesPopup
      :title="buildTitle(getSpecimenById(slideModalState.specId), protocolOptions, bodyPartOptions)"
      :specimenId="slideModalState.specId"
      :popupVisible.sync="isSlideImagesOpen"
      :data="slideModalState.data"
    ></SlideImagesPopup>
    <div class="specimen-section__header d-flex justify-content-between">
      <div ref="pageTitle" class="d-inline-flex p-1">
        <page-title>Results</page-title>
        <icon-button
          icon="expand-alt"
          class="btn btn-outline-info specimen-section__header-icon"
          @click="toggleAccordion"
        />
        <icon-button
          class="btn btn-outline-info specimen-section__header-icon ml-2"
          @click="toggleLock"
          :icon="isUnlocked ? 'lock-open' : 'lock'"
          v-tooltip="'Click to unlock free text editors'"
        />
        <loader size="small" v-show="isLoading" />
      </div>
      <div class="d-flex" v-shortkey="shortkeySite" @shortkey="handleShortKey">
        <div v-if="editType" class="align-self-end">
          <h2 class="specimen-section__title">{{ currentEditType }}</h2>
        </div>
        <div v-if="editType" class="align-self-end mb-2">
          <icon-button
            icon="pen-alt"
            class="btn btn-outline-info specimen-section__header-icon mr-3"
            @click="toggleReReport"
          />
        </div>
        <select-input
          ref="specDropdown"
          v-if="specimens.length"
          label="Specimen"
          v-model="dropdownSpecimenId"
          :items="specimens"
          display-expr="specimenOrder"
          value-expr="id"
          accessKey="i"
          width="7em"
        />
      </div>
    </div>
    <div v-if="accordionView" id="specimen-accordian">
      <div id="accordion" v-if="specimens.length">
        <accordion
          v-for="(specimen, index) in specimens"
          :key="index"
          :id="index"
          :title="buildTitle(specimen, protocolOptions, bodyPartOptions)"
          :isExpanded="selectedIndex === index"
          accordionId="accordion"
          ref="specimen"
          @click="setSelected(specimen, index)"
        >
          <template v-slot:after_title>
            <div>
              <!-- IP-1345: moved to case header -->
              <!-- <button
                v-if="hasSlideImages(specimen.id)"
                class="btn btn-success"
                @click="launchSpecimenImages(specimen.id)"
              >
                Slides
              </button> -->
            </div>
          </template>
          <ResultSpecimenForm
            ref="specimenForm"
            :isLoading="isLoading"
            v-if="selectedIndex === index"
            :specimens="specimens"
            :key="currentSpecimenOrder"
            v-model="selectedSpecimen"
            @save="submitResults"
            :isUnlocked="isUnlocked"
            :checkModified="true"
          />
        </accordion>
      </div>
      <div v-else class="text-center">
        <h4 class="text-muted mx-auto">No Specimens found.</h4>
      </div>
    </div>
    <div v-else>
      <DxGridWithSearch
        class="m-0"
        id="specimmenGridContainer"
        width="auto"
        ref="specimenGrid"
        :data-source="specimensDataSource"
        :columns="columns"
        :selectedRowKeys="selectedSpecimenOrder"
        @row-click="onSelectionChanged"
        noDataText="No Specimens found"
        @initialized="setGridInstance"
        :scrolling="scrolling"
        :show-borders="true"
        :hover-state-enabled="true"
        :showColumnLines="true"
        :showRowLines="true"
        :wordWrapEnabled="false"
        :allowColumnResizing="true"
        columnResizingMode="widget"
        :columnChooser="true"
        gridName="specimenResults"
        :searchPanel="null"
        :selection="null"
        :showFilterRow="false"
      >
        <template v-slot:isModifiedTemplate="{ data }">
          <span :class="data.value ? 'text-danger' : ''">{{ data.text }}</span>
        </template>
      </DxGridWithSearch>
      <div v-if="selectedSpecimen">
        <div class="d-flex results-header mt-2 justify-content-between align-items-center">
          <h5
            class="d-flex align-items-center"
            v-html="buildTitle(selectedSpecimen, protocolOptions, bodyPartOptions)"
          ></h5>
          <!-- IP-1345: Moved to case header -->
          <!-- <button
            v-if="hasSlideImages(selectedSpecimen.id)"
            class="btn btn-success"
            @click="launchSpecimenImages(selectedSpecimen.id)"
          >
            Slides
          </button> -->
        </div>
        <ResultSpecimenForm
          ref="specimenForm"
          :isLoading="isLoading"
          v-model="selectedSpecimen"
          @save="submitResults"
          :isUnlocked="isUnlocked"
        />
      </div>
    </div>
  </div>
</template>
<script>
import SpecimenService from "../../services/specimen";
import DropdownService from "../../services/dropdown";
import MacrosService from "../../services/macros";
import Accordion from "@/components/common/Accordion";
import ProviderAPI from "@/services/providers";
import { mapGetters, mapState } from "vuex";
import defaultSpecimen from "../../modules/specimen";
import ResultSpecimenForm from "@/components/forms/Accession/ResultSpecimen";
import { debounce } from "lodash";
import dialog from "@/modules/dialog";
import {
  getTextFromHtml,
  buildTitle,
  altKey,
  checkIfProxy,
  isModalOpen,
  getEditTypes,
  getCaseProstateData
} from "../../modules/helpers";
import Loader from "@/components/common/Loader.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import IconButton from "../common/IconButton.vue";
import physicianPopup from "@/mixins/physicianPopup";
import ArrayStore from "devextreme/data/array_store";
import { CaseStatusEnum } from "@/modules/enums";
import GCodePopup from "../GCodePopup.vue";
import { getSpecimenGCode } from "@/modules/getSpecimenGCode";
import SelectInput from "../common/SelectInput.vue";
import SlideImagesPopup from "../common/SlideImagesPopup.vue";
import eventBus, {
  LAST_SPECIMEN_SAVED,
  LOCK_EDITORS,
  PRESSED_SIGNOUT_KEY,
  UNLOCK_EDITORS,
  SIGN_CASE_FROM_SHORTKEY,
  OPEN_SLIDE_IMAGES
} from "@/modules/eventBus";
import { handleErrors } from "@/modules/handleErrors";
import { CasesApi } from "@/services";
import DxGridWithSearch from "../common/DxGridWithSearch.vue";
import launchImage from "@/modules/launchImage";

export default {
  components: {
    Accordion,
    ResultSpecimenForm,
    Loader,
    PageTitle,
    IconButton,
    GCodePopup,
    SelectInput,
    SlideImagesPopup,
    DxGridWithSearch
  },
  mixins: [physicianPopup],
  data() {
    return {
      isSlideImagesOpen: false, //Added by IP-1095
      slideModalState: {
        data: [],
        specId: null
      },
      scrolling: {
        showScrollbar: "always",
        useNative: true
      },
      slideWindows: {},
      selection: { mode: "single" },
      accessionInfo: {},
      selectedGCode: null,
      suggestions: [],
      inputProps: {
        class: "form-control"
      },
      editTypes: getEditTypes(),
      selected: null,
      sectionConfigs: {
        icd: {
          onSelected: selected => {
            this.selected = selected.item;
          }
        }
      },
      macroHeader: { header: "Results Macros", classes: {}, checked: false },
      macros: [],
      timeout: null,
      protocolOptions: [],
      icdOptions: [],
      cptOptions: [],
      siteOptions: [],
      specimenSlideImageUrl: null,
      resultMacrosOptions: [],
      diagnosisSummaries: [],
      defaultSpecimen: defaultSpecimen,
      test: "",
      originalSpecimens: [],
      focusedRowIndex: undefined,
      selectedSpecimen: null,
      selectedIndex: null,
      specimenGrid: {},
      dropdownSpecimenId: null,
      isUnlocked: false,
      routeLeaveCalled: false,
      shortkeySite: altKey("i"),
      shortkeySlideImage: altKey("-"),
      signAfterSave: false,
      staticCaseId: null
    };
  },

  async beforeRouteLeave(to, from, next) {
    if (this.routeLeaveCalled) {
      return;
    }
    this.routeLeaveCalled = true;
    let specClear = await this.checkSpecimens();
    if (!specClear) {
      this.routeLeaveCalled = false;
      return;
    }
    if (to?.name !== "Specimen") {
      this.$store.commit("accessionStore/setCurrentSpecimen", {});
    }
    next();
  },
  async beforeRouteUpdate(to, from, next) {
    if (this.routeLeaveCalled) {
      return;
    }
    this.routeLeaveCalled = true;
    let specClear = await this.checkSpecimens();
    if (!specClear) {
      this.routeLeaveCalled = false;
      return;
    }
    next();
  },
  created() {
    const { labPrefix } = this.caseDetails;
    DropdownService.getProtocol(labPrefix).then(res => {
      this.protocolOptions = res || [];
    });
    this.$store.dispatch("dropdowns/getBodyParts");
  },

  computed: {
    ...mapState({
      labSettings: state => state.labSettings,
      currentUser: state => state.currentUser,
      token: state => state.token,
      currentLab: state => state.currentLab,
      reasonForChange: state => state.accessionStore.reasonForChange,
      editType: state => state.accessionStore.editType,
      caseDetails: state => state.accessionStore.caseDetails,
      specimens: state => state.accessionStore.specimens,
      isLoading: state => state.accessionStore.loading,
      currentSpecimen: state => state.accessionStore.currentSpecimen,
      accessionMode: state => state.applicationSettings.accessionMode,
      textEditors: state => state.applicationSettings.textEditors,
      bodyPartOptions: state => state.dropdowns.bodyParts,
      specimenToLoad: state => state.accessionStore.specimenToLoad,
      caseHeader: state => state.accessionStore.caseHeader,
      slideImages: state => state.accessionStore.slideImages,
      viewedSlideImages: state => state.sessionDetails.viewedSlideImages,
      autoOpenSlideImages: state => state.applicationSettings.autoOpenSlideImages,
      SlideImagesApplicationHandler: state => state.labSettings.SlideImagesApplicationHandler,
      OpenFirstSlideImage: state => state.labSettings.OpenFirstSlideImage,
      shouldPrintOrders: state => state.accessionStore.shouldPrintOrders,
      autoOpenSpecimenResults: state => state.applicationSettings.autoOpenSpecimenResults
    }),
    ...mapGetters("accessionStore", ["caseStatus", "primaryPathologist"]),
    accordionView: {
      get() {
        return this.$store.state.applicationSettings.accordionView;
      },
      set(value) {
        this.$store.commit("applicationSettings/setAccordionView", value);
        return value;
      }
    },
    selectedSpecimenImages() {
      if (this.selectedSpecimen?.id && this.slideImages?.length > 0) {
        return this.slideImages.find(e => e.specimenId === this.selectedSpecimen.id);
      }
      return null;
    },
    slideImagesDataSource() {
      if (this.selectedSpecimenImages !== null) {
        const imageUrls = [];
        if (this.selectedSpecimenImages.slideImageUrls) {
          imageUrls.concat(this.selectedSpecimenImages);
        }
        if (this.selectedSpecimenImages.specimenSlideImageUrl) {
          imageUrls.push(this.selectedSpecimenImages.specimenSlideImageUrl);
        }
        return { imageUrls };
      }
      return [];
    },

    specimensDataSource() {
      return new ArrayStore({
        data: this.specimens,
        key: "specimenOrder"
      });
    },
    currentEditType() {
      const editType = this.editTypes.find(type => type.id === this.editType);
      if (editType) {
        return editType.displayName;
      }
      return "";
    },
    slideImagesColumns() {
      return [
        {
          dataField: "type",
          dataType: "string",
          caption: "Type"
        },
        {
          dataField: "Action",
          dataType: "string",
          caption: "Action",
          cellTemplate: "actions"
        }
      ];
    },
    columns() {
      return [
        {
          dataField: "specimenOrder",
          dataType: "string",
          caption: "Specimen",
          width: "4.75rem"
        },
        {
          caption: "Protocol",
          dataType: "number",
          dataField: "protocolId",
          lookup: {
            dataSource: this.protocolOptions,
            valueExpr: "id",
            displayExpr: "displayName"
          },
          width: "10rem"
        },
        {
          caption: "Site",
          dataType: "string",
          calculateCellValue: this.calcSite,
          width: "10rem"
        },
        {
          dataField: "icdCodes",
          caption: "ICD10 Codes",
          calculateCellValue: data => this.calculateIcdDisplay(data),
          width: "6rem"
        },
        {
          dataField: "diagnosis",
          dataType: "string",
          calculateCellValue: data => data.diagnosis && getTextFromHtml(data.diagnosis),
          minWidth: "250px",
          width: "40%",
          cssClass: "truncate"
        },
        {
          dataField: "macroIsModified",
          dataType: "boolean",
          caption: "Modified",
          lookup: {
            dataSource: [
              { displayName: "Yes", id: true },
              {
                displayName: "No",
                id: false
              }
            ],
            displayExpr: "displayName",
            valueExpr: "id"
          },
          cellTemplate: "isModifiedTemplate",
          width: "4.5rem"
        },
        {
          dataField: "resultsMacros",
          calculateCellValue: data =>
            data.resultsMacros
              .map(e => e.displayName)
              .join(", ")
              .toUpperCase(),
          visible: false
        }
      ];
    },
    selectedSpecimenOrder() {
      if (this.selectedSpecimen) {
        return [this.selectedSpecimen.specimenOrder];
      }
      return [];
    },
    currentSpecimenOrder() {
      if (this.selectedSpecimen) {
        return this.selectedSpecimen.specimenOrder;
      }
      return [];
    },
    mappedProtocol() {
      return this.protocolOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    mappedBodyPart() {
      return this.bodyPartOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    mappedSite() {
      return this.siteOptions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: item.displayName
          }),
        {}
      );
    },
    canSignOut() {
      const { isPathologist, id } = this.currentUser;
      if (
        (isPathologist && id === this.primaryPathologist?.guid) ||
        checkIfProxy(this.primaryPathologist?.guid)
      ) {
        const { status } = this.caseHeader;
        if (
          [
            CaseStatusEnum.ResultedOnHold,
            CaseStatusEnum.SignedOnHold,
            CaseStatusEnum.Resulted,
            CaseStatusEnum.Signed,
            CaseStatusEnum.ResultedAgain,
            CaseStatusEnum.SignedAgain
          ].includes(status)
        ) {
          return true;
        }
      }
      return false;
    }
  },
  watch: {
    selectedSpecimen: {
      deep: true,
      handler(nv) {
        this.$store.commit("accessionStore/setCurrentSpecimen", nv ?? {});
        this.dropdownSpecimenId = nv?.id || null;
      }
    },
    dropdownSpecimenId: {
      handler(nv) {
        if (nv !== this.selectedSpecimen?.id) {
          const newSpecimenIndex = this.specimens.findIndex(e => e.id === nv);
          this.setSelected(this.specimens[newSpecimenIndex], newSpecimenIndex);
        }
      }
    },
    "caseDetails.caseId": {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.staticCaseId = parseInt(nv);
        }
      }
    }
  },
  mounted() {
    if (this.accordionView && this.specimens?.length) {
      console.log("User is in accordion view.");
      this.handleRenderResultForm();
    }
    eventBus.$on(PRESSED_SIGNOUT_KEY, () => this.handleSignoutKey());
    eventBus.$on(OPEN_SLIDE_IMAGES, specimenId => this.launchSpecimenImages(specimenId, true));
  },
  beforeDestroy() {
    this.autoPrintOrders();
    eventBus.$off(PRESSED_SIGNOUT_KEY);
    eventBus.$off(OPEN_SLIDE_IMAGES);
  },
  methods: {
    getSpecimenById(specId) {
      return this.specimens.find(e => e.id === specId);
    },
    handleSlideImageShortkey() {
      if (isModalOpen()) {
        return;
      }
      if (this.selectedSpecimen?.id) {
        this.launchSpecimenImages(this.selectedSpecimen.id, true);
      }
    },
    launchImage(data) {
      launchImage(data.url, this.SlideImagesApplicationHandler);
    },
    toggleReReport() {
      this.$store.commit("setReReportPopup", !this.$store.state.accessionStore.reReportPopup);
    },
    handleViewPathReport() {
      const { caseId } = this.$route.params;
      this.$store.dispatch("report/viewPathReport", { caseId });
    },
    async handleRenderResultForm() {
      const [specimen, idx] = this.getNextSpecimenToEdit(this.specimens);
      if (specimen) {
        this.setSelected(specimen, idx);
        if (
          this.currentUser.isPathologist &&
          this.primaryPathologist &&
          this.primaryPathologist?.guid !== this.currentUser.id &&
          [
            CaseStatusEnum.Acc,
            CaseStatusEnum.Demo,
            CaseStatusEnum.Spec,
            CaseStatusEnum.Gross,
            CaseStatusEnum.Orders,
            CaseStatusEnum.ResultedOnHold,
            CaseStatusEnum.Resulted,
            CaseStatusEnum.ResultedAgain
          ].includes(this.caseStatus)
        ) {
          await window.alert(
            '<p style="font-size:1.25rem; color: red;"><b>Warning:</b> You are <b>NOT</b> the <b>primary pathologist</b> on the case,\n therefore you will <b>NOT</b> be able to <b>sign</b> this case out.</p>'
          );
        }
      }
    },
    hasSlideImages(specId) {
      if (this.slideImages?.length > 0) {
        const slides = this.slideImages.find(e => e.specimenId === specId);
        return slides?.slideImageUrls?.length > 0 || slides?.specimenSlideImageUrl;
      }
      return false;
    },
    generateImageUrls(specId) {
      if (!specId || this.slideImages?.length === 0) {
        return [];
      }
      const slides = this.slideImages.find(e => e.specimenId === specId);
      const imageUrls = [
        ...(slides.slideImageUrls || []).map(e => ({
          type: "Slide",
          url: e.slideImageUrl,
          blockNum: e.blockNumber,
          slideLabel: e.slideLabel,
          slideProcedure: e.slideProcedure
        }))
      ];
      if (slides.specimenSlideImageUrl) {
        imageUrls.push({ type: "Specimen", url: slides.specimenSlideImageUrl });
      }
      return imageUrls;
    },
    async launchSpecimenImages(specId, isExternal = false) {
      const slideImages = this.generateImageUrls(specId);
      // ! This is exclusively for preventing auto launch of slide images, if its already been launched in the session.
      if (this.viewedSlideImages.includes(specId) && !isExternal) {
        return;
      }
      this.$store.commit("sessionDetails/setViewedSlideImages", [
        ...this.viewedSlideImages,
        specId
      ]);
      if (slideImages.length > 1 && !this.OpenFirstSlideImage) {
        this.isSlideImagesOpen = true;
        this.slideModalState = {
          data: slideImages,
          specId
        };
      } else if (slideImages.length) {
        this.launchImage(slideImages[0]);
      }
    },
    getNextSpecimenToEdit(specimens) {
      if (this.accessionMode === "result" && specimens?.length && !this.selectedSpecimen) {
        if (this.specimenToLoad) {
          const specimenIndex = specimens
            .map(e => e.specimenOrder)
            .indexOf(this.specimenToLoad.toUpperCase());
          this.$store.commit("accessionStore/setSpecimenToLoad", null);
          if (specimens[specimenIndex]?.id) {
            return [specimens[specimenIndex], specimenIndex];
          } else {
            window.notify(`Could not find Specimen "${this.specimenToLoad}"`, "error");
          }
        }
        for (let idx = 0; idx < specimens.length; idx++) {
          const specimen = specimens[idx];
          const hasDiagnosis = specimen.diagnosis
            ? !!getTextFromHtml(specimen.diagnosis).trim()
            : false;
          if (!hasDiagnosis) {
            return [specimen, idx];
          }
        }
      }
      if (this.autoOpenSpecimenResults) {
        let index = 0;
        if (this.selectedSpecimen) {
          const currentSpecimenIndex = specimens.map(e => e.id).indexOf(this.selectedSpecimen.id);
          if (currentSpecimenIndex < this.specimens.length) {
            index = currentSpecimenIndex + 1;
            return [specimens[index], index];
          }
        } else {
          return [specimens[0], 0];
        }
      }
      return [];
    },
    setGridInstance({ component }) {
      this.specimenGrid = component;
      this.handleRenderResultForm();
    },
    checkMacroContent: debounce(async function (specimen) {
      let header = "Results Macros";
      let classes = "";
      if (specimen && specimen.resultsMacros.length) {
        const macros = await Promise.all(
          specimen.resultsMacros.map(e => MacrosService.getMacroDetails(e.id))
        );
        for (const macro of macros) {
          if (!macro.microscopic && header != "Results Macros (G)") {
            header = "Results Macros (G)";
          }
        }
      }
      return (this.macroHeader = { header, classes, checked: true });
    }, 500),
    setPrimaryContact(id) {
      this.selectedSpecimen.contact = this.selectedSpecimen.contact.map(entry => {
        if (entry?.contactId === id) {
          entry.isPrimary = true;
        } else {
          entry.isPrimary = false;
        }
        return entry;
      });
    },
    fetchContacts(searchValue) {
      let options = {
        sortFields: [
          {
            field: "Provider.LastName",
            ascending: true
          }
        ],
        searchPaths: ["Provider.FirstName", "Provider.LastName", "ProviderAddress.OfficeName"],
        searchPathsInclusive: true,
        searchValue
      };
      return ProviderAPI.searchContacts(options);
    },
    appendMacro(macro) {
      if (macro.macroType != 0) {
        return;
      }
      if (this.selectedSpecimen) {
        this.selectedSpecimen.resultsMacros.push({
          id: macro.macroId,
          displayName: macro.macroName,
          isDeleted: false
        });
      }
    },
    removeSelectedMacro(specimen, index) {
      if (specimen) {
        specimen.resultsMacros.splice(index, 1);
      }
    },
    getSpecimens(id) {
      return SpecimenService.getSpecimen(id).then(response => {
        this.specimens =
          response?.map(val => ({
            ...val,
            blockNum: val.cassettes ? val.cassettes.length : 0,
            gross: val.gross ? val.gross : "",
            clinical: val.clinical ? val.clinical : "",
            interfaceDiagnosis: val.interfaceDiagnosis ? val.interfaceDiagnosis : "",
            diagnosis: val.diagnosis ? val.diagnosis : "",
            microscopic: val.microscopic ? val.microscopic : "",
            notes: val.notes ? val.notes : "",
            caseNotes: val.caseNotes ? val.caseNotes : "",
            initIcdCodes: val.icdCodes ? val.icdCodes : [],
            initCptCodes: val.cptCodes ? val.cptCodes : []
          })) || [];
        this.originalSpecimens = [...this.specimens];
      });
    },
    async setSelected(specimen, index) {
      if (this.selectedSpecimen) {
        const isFormDirty = this.checkForChanges();
        if (isFormDirty) {
          const confirm = await dialog(
            "You may have unsaved data. Would you like to continue without saving? Any changes you have made will be lost.",
            "IntelliPath Pro",
            "Yes",
            "No",
            false
          ).show();
          if (!confirm) {
            this.dropdownSpecimenId = this.currentSpecimen.id;
            return;
          }
        }
      }
      if (index === this.selectedIndex) {
        this.selectedSpecimen = null;
        this.selectedIndex = null;
      } else {
        this.selectedSpecimen = specimen;
        this.selectedIndex = index;
        if (this.autoOpenSlideImages) {
          this.launchSpecimenImages(specimen.id);
        }
      }
    },
    renderSuggestion(suggestion) {
      return suggestion.item;
    },
    toggleAccordion() {
      if (!this.accordionView && this.selectedSpecimenOrder.length) {
        this.$nextTick(() => {
          // const index = this.specimenGrid.getRowIndexByKey(this.selectedSpecimenOrder[0]);
          this.selectedIndex = this.specimens.findIndex(
            specimen => this.selectedSpecimenOrder.reduce((acc, e) => e) === specimen?.specimenOrder
          );
        });
      }
      this.accordionView = !this.accordionView;
    },
    buildTitle,
    submitResults(specimen) {
      const allSpecimens = [...this.specimens.filter(s => s.id !== specimen.id), specimen];
      const allDiagnosis = allSpecimens
        .filter(e => e.diagnosis)
        .map(e => {
          return {
            ...e,
            ...getCaseProstateData(specimen)
          };
        });
      return this.resultSpecimens(allDiagnosis);
    },
    async saveAndContinue(specimen) {
      this.$store.commit("accessionStore/setLoading", true);
      try {
        await this.$store.dispatch("accessionStore/resultSingleSpecimen", specimen);
      } catch (e) {
        window.notify("Error saving single specimen.", "error");
        this.dropdownSpecimenId = specimen.id;
        const prevSpecimenIndex = this.specimens.findIndex(e => e.id === specimen.id);
        this.selectedSpecimen = specimen;
        this.selectedIndex = prevSpecimenIndex;
        this.$store.commit("accessionStore/setLoading", false);
        return;
      }
      this.$store.commit("accessionStore/setLoading", false);
      window.notify(`Success`, "success", 2000, { at: "center", of: "#navbar" });
      this.handleViewPathReport(specimen.caseId);
    },
    async resultSpecimens(specimens) {
      let success = false;
      // const start = new Date().getTime();
      // function timeSinceStart() {
      //   return new Date().getTime() - start + " ms";
      // }
      try {
        const confirmStatus = await this.getPhysicianPopup(5, specimens); //Specimen Save
        if (!confirmStatus) {
          this.isLoading = false;
          window.notify("User cancelled action.", "error");
          return;
        }

        this.$store.commit("accessionStore/setLoading", true);
        this.$store.dispatch("logTimeSinceBase", "dispatched to accession store");
        await this.$store.dispatch("accessionStore/resultCaseSpecimens", specimens);
        this.$store.dispatch("logTimeSinceBase", "finish dispatch from accession store");
        if (!this.signAfterSave) {
          window.notify(`Success`, "success", 2000, { at: "center", of: "#navbar" });
        }
        success = true;
      } catch (error) {
        const message = error?.response?.data?.message || error?.response?.data;
        if (message && /g\s?code/i.test(message)) {
          const gCode = await getSpecimenGCode(message, specimens);
          return this.resultSpecimens(
            specimens.map((specimen, idx) => {
              if (this.selectedSpecimen) {
                if (specimen.id === this.selectedSpecimen?.id) {
                  specimen.cptCodes = [{ id: gCode }, ...specimen.cptCodes];
                }
              } else if (idx === 0) {
                specimen.cptCodes = [{ id: gCode }, ...specimen.cptCodes];
              }
              return specimen;
            })
          );
        } else {
          handleErrors(error);
        }
      } finally {
        if (success) {
          this.$store.dispatch("logTimeSinceBase", "** Total save time from click. **");
          this.$store.commit("accessionStore/setLoading", false);
          const specimenIndex = this.specimens.map(e => e.id).indexOf(this.selectedSpecimen.id);
          // If the case is not resulted yet, we will try to move the user to the next specimen.
          const newCaseStatus = await CasesApi.getCaseStatus(this.caseDetails.caseId);
          if (![6, 9, 12].includes(newCaseStatus)) {
            let specimenFound = false;
            //Find the next specimen without a diagnosis.
            for (let idx = specimenIndex; idx < this.specimens.length; idx++) {
              const specimen = this.specimens[idx];
              const { diagnosis } = specimen;
              if (!diagnosis) {
                this.selectedIndex = idx;
                this.selectedSpecimen = specimen;
                specimenFound = true;
                break;
              }
            }
            // If it reaches the end, it loops back to the beginning.
            if (!specimenFound && !this.autoOpenSpecimenResults) {
              this.selectedSpecimen = null;
              this.selectedIndex = null;
            }
          } else {
            if (
              [
                CaseStatusEnum.ResultedOnHold,
                CaseStatusEnum.Resulted,
                CaseStatusEnum.ResultedAgain
              ].includes(newCaseStatus) &&
              !this.autoOpenSpecimenResults
            ) {
              this.selectedSpecimen = null;
              this.selectedIndex = null;
              if (this.signAfterSave && this.canSignOut) {
                eventBus.$emit(SIGN_CASE_FROM_SHORTKEY);
              }
            }
          }
          this.$nextTick(() => {
            if (specimenIndex === this.specimens.length - 1) {
              eventBus.$emit(LAST_SPECIMEN_SAVED);
            }
          });
          if (this.autoOpenSpecimenResults) {
            const [specimen, idx] = this.getNextSpecimenToEdit(this.specimens);
            if (specimen && specimen?.id !== this.selectedSpecimen?.id) {
              this.setSelected(specimen, idx);
            } else {
              this.selectedSpecimen = this.currentSpecimen;
            }
          } else {
            if (specimens.length === this.specimens.length) {
              this.selectedSpecimen = null;
              this.selectedIndex = null;
            }
          }
          this.handleViewPathReport(this.$route.params.caseId);
          this.signAfterSave = false;
        }
      }
    },

    onSelectionChanged(e) {
      const { rowIndex, data } = e;
      this.setSelected(data, rowIndex);
    },
    calcProtocol(data) {
      let protocol = this.mappedProtocol[data.protocolId] || "";
      return protocol;
    },
    calcSite(data) {
      let bodyPart = this.mappedBodyPart[data.bodyPartId]
        ? this.mappedBodyPart[data.bodyPartId] + ", "
        : "";
      let site = data.site ? data.site : "";
      return [bodyPart, site].join("");
    },
    getSpecimenRichText(specimen) {
      return {
        diagnosis: specimen.diagnosis ? getTextFromHtml(specimen.diagnosis) : "",
        caseNotes: specimen.caseNotes ? getTextFromHtml(specimen.caseNotes) : "",
        gross: specimen.gross ? getTextFromHtml(specimen.gross) : "",
        clinical: specimen.clinical ? getTextFromHtml(specimen.clinical) : "",
        notes: specimen.notes ? getTextFromHtml(specimen.notes) : "",
        microscopic: specimen.microscopic ? getTextFromHtml(specimen.microscopic) : ""
      };
    },
    handleShortKey() {
      if (this.specimens.length === 1) {
        if (this.dropdownSpecimenId !== this.specimens[0].id) {
          this.dropdownSpecimenId = this.specimens[0].id;
        } else {
          this.dropdownSpecimenId = null;
        }
        return;
      }
      if (!this.$refs.specDropdown.isFocused) {
        this.$refs.specDropdown.focus();
      } else {
        this.$refs.specDropdown.focusOut();
        const specFormRef =
          this.$refs?.specimenForm?.$refs?.site || this.$refs.specimenForm[0].$refs.site;
        this.$nextTick(() => {
          if (specFormRef) {
            specFormRef.focus();
          }
        });
      }
    },
    toggleLock() {
      if (this.isUnlocked) {
        this.$store.dispatch("applicationSettings/setUserSettings", {
          textEditors: this.textEditors
        });
        eventBus.$emit(LOCK_EDITORS);
      } else {
        eventBus.$emit(UNLOCK_EDITORS);
      }
      this.isUnlocked = !this.isUnlocked;
    },
    async checkSpecimens() {
      if (this.selectedSpecimen) {
        const isFormDirty = this.checkForChanges();
        if (isFormDirty && this.token) {
          const confirm = await dialog(
            "You may have unsaved data. Unsaved will be lost if you proceed.<br> <span class='mx-auto'>Are you sure?</span>",
            "IntelliPath Pro",
            "Yes",
            "No"
          ).show();
          if (!confirm) {
            return false;
          }
        }
      }
      return true;
    },
    checkForChanges() {
      const getFields = checkedObject => {
        const fieldsToCheck = [
          "pathologists",
          "resultsMacros",
          "icdCodes",
          "prostateLength",
          "prostatePieces",
          "gleasonScore",
          "tumorSize",
          "tables"
        ];
        let returnObject = {};
        for (const field of fieldsToCheck) {
          returnObject[field] = checkedObject[field];
        }
        returnObject = {
          ...returnObject,
          ...getCaseProstateData(checkedObject),
          ...this.getSpecimenRichText(checkedObject)
        };
        return returnObject;
      };
      if (this.selectedSpecimen) {
        const originalSpecimen = {
          ...getFields(this.selectedSpecimen)
        };
        const currentSpecimen = {
          ...getFields(this.currentSpecimen)
        };
        return JSON.stringify(originalSpecimen) !== JSON.stringify(currentSpecimen);
      } else {
        return false;
      }
    },
    handleSignoutKey() {
      let shouldSignCase = false;
      const { isPathologist, id } = this.currentUser;
      if (
        (isPathologist && id === this.primaryPathologist?.guid) ||
        checkIfProxy(this.primaryPathologist?.guid)
      ) {
        shouldSignCase = true;
      }
      const shouldSaveCase = this.checkForChanges();
      if (shouldSaveCase && this.selectedSpecimen) {
        this.$store.commit("accessionStore/setLoading", true);
        const specimenFormRefClick =
          this.$refs.specimenForm?.saveClick$ || this.$refs.specimenForm[0].saveClick$;
        specimenFormRefClick.next();
        this.signAfterSave = shouldSignCase;
      } else if (shouldSignCase) {
        eventBus.$emit(SIGN_CASE_FROM_SHORTKEY);
      }
    },
    calculateIcdDisplay({ icdCodes }) {
      if (!icdCodes?.length) {
        return "";
      }
      return icdCodes.map(e => e.displayCode).join(", ");
    },
    autoPrintOrders() {
      if (this.shouldPrintOrders) {
        this.$store.commit("accessionStore/setShouldPrintOrders", false);
        this.$store.dispatch("accessionStore/autoPrintOrders", this.staticCaseId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
pre {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

::v-deep span.string {
  color: green;
}

::v-deep .number {
  color: darkorange;
}

::v-deep .boolean {
  color: blue;
}

::v-deep .null {
  color: magenta;
}

::v-deep .key {
  color: red !important;
}
::v-deep .yellow .layout-container {
  width: 100%;
}

.multiselect__single {
  background: unset;
  color: unset;
  padding: unset;
  width: unset;
}

.specimen-section {
  &__header {
    display: flex;
    align-items: center;
  }

  &__header-icon {
    color: $primary;
    margin-left: 30px;
    cursor: pointer;
  }

  &__header-btn {
    margin-left: auto !important;
  }
}

.small-input {
  margin-left: 20px;
  width: 50px;
}

.tag-btn {
  padding: 0px;
  margin: 0px;
}

.icd-col {
  padding-top: 2px;
}
.icd-select {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
}

.custom__icdtag {
  border: white solid 1px;
}

.icd-select >>> .multiselect__content-wrapper {
  width: 500px !important;
}

.results-header {
  background: #e6e6e6;
  padding: 5px 25px;
  margin: 0px auto;
}

.general-information {
  border: 1px solid #dee2e6 !important;
  border-radius: 0.25rem;
  padding: 5px 25px;
  margin: 10px auto;
}
.tag-input {
  width: 100%;
  border: 1px solid #eee;
  font-size: 1rem;
  font-weight: 500;
  height: 50px;
  box-sizing: border-box;
  padding: 0 10px;
  border-radius: 0.25rem;
}

.tag-input__tag > span {
  cursor: pointer;
  opacity: 0.75;
}
::v-deep .gross-col {
  text-overflow: ellipsis;
}
::v-deep
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)
  > td:not(.dx-focused) {
  cursor: pointer;
}
</style>
